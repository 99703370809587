
import axios from "axios";
// test 24

// export const baseIP = '160.242.26.156:3000/site_ispaj_back/public'
export const baseIP = 'ispaj.co.ao/back'

export const baseURL = `https://${baseIP}`
const api = axios.create({
    baseURL: baseURL,
})
export default api