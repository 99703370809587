import { useLocation, useNavigate } from "react-router-dom"
import Header from "../components/header"
import Footer from "../components/footer"
import '../css/about.css'
import { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import HealthPage from "../components/healthPage"
import SocialsPage from "../components/socialsPage"
import EnginesPage from "../components/enginesPage"
import axios from "axios"
import { baseURL } from "../api/api"

const wrapperVariants = {
    open: {
      scaleY: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    closed: {
      scaleY: 0,
      transition: {
        when: "afterChildren",
        staggerChildren: 0.1,
      },
    },
  };
  
  const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  };
  
  const itemVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        when: "beforeChildren",
      },
    },
    closed: {
      opacity: 0,
      y: -15,
      transition: {
        when: "afterChildren",
      },
    },
  };
  
  

  const Option = ({departamentos, item, text, section, setSection, setOpen }) => {
    const navigate = useNavigate()
    return (
      <motion.li
        variants={itemVariants}
        onClick={() => {
          
          
          !section.includes(text) && setSection(text)
          !section.includes(text) && localStorage.setItem('path', text)
          // window.location.reload()
          // console.log('text', text)
          navigate('/cursos', {
            state: {
              ...item
            }
          })
          setOpen(false)
          window.location.reload()
          
        // localStorage.setItem('path', `${item?.info?.titulo}`)
          
          
        }}
        className={section.includes(text) ? 'optionItem1' : 'optionItem'}
      >
        
        <span>{text}</span>
      </motion.li>
    );
  };
  const StaggeredDropDown = ({departamentos, section, setSection}) => {
    
    const [open, setOpen] = useState(false);
    useEffect(() => {
      console.log('open', open)
    }, [open])
    const menuRef = useRef(null)
    useEffect(() => {
      const handleClickOutside = (e) => {
          if (menuRef.current && !menuRef.current.contains(e.target)){
              setOpen(false)
          }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
          document.removeEventListener('mousedown', handleClickOutside)
      }
  }, [])
    return (
      <div style={{zIndex: 100}} className="aboutOptions" ref={menuRef}>
        <motion.div animate={open ? "open" : "closed"} className="relative">
          <button
            onClick={() => setOpen((pv) => !pv)}
            className="aboutOptionsButton"
          >
            <span className="aboutOptionsText">Navegação Adicional</span>
            <motion.span variants={iconVariants}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
</svg>
            </motion.span>
          </button>
  
          <motion.ul
            initial={wrapperVariants.closed}
            variants={wrapperVariants}
            style={{ originY: "top", translateX: 0}}
            className="aboutOptionsList"
          >
            {departamentos?.map((item) => {
                        return (
                          <Option departamentos={departamentos} item={item} setOpen={setOpen} section={section} setSection={setSection}text={`${item?.info?.titulo}`} />
                        )
                        })}
            {/* <Option setOpen={setOpen} section={section} setSection={setSection}text="Ciências de Saúde" />
            <Option setOpen={setOpen} section={section} setSection={setSection} text="Ciências Sociais e Económicas" />
            <Option setOpen={setOpen} section={section} setSection={setSection} text="Ciências das Engenharias/Exatas" /> */}
            
          </motion.ul>
        </motion.div>
      </div>
    );
  };
const Courses = () => {
    const [section, setSection] = useState('Ciências da Saúde')
    const [curso, setCurso] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()
    
    
    
    const [departamentos, setDepartmentos] = useState([])
    useEffect(() => {
      axios.get(`${baseURL}/api/departamentos`)
      .then(res => {
        setDepartmentos([...res.data])

        if (location?.state?.id){
          console.log('locationnnnn', location.state.id)
            axios.get(`${baseURL}/api/department/${location?.state?.id}`)
          .then(res => {
            setCurso(res.data)
          })
          .catch(err => {
            console.log('departmentError', location.state, err.response.data)
          })
        }else {
          console.log('else', localStorage.getItem('path'))
          if (localStorage.getItem('path').toLowerCase().includes('saúde')) {
            console.log('saudeeeeeeeee')
          axios.get(`${baseURL}/api/department/${[...res.data][0]?.id}`)
          .then(res => {
            setCurso(res.data)
          })
          .catch(err => {
            console.log('departmentError1', err.response.data)
          })
         }else if (localStorage.getItem('path').toLowerCase().includes('sociais')) {
          console.log('socialssss')
          axios.get(`${baseURL}/api/department/${[...res.data][1]?.id}`)
                        .then(res => {
                          setCurso(res.data)
                        })
                        .catch(err => {
                          console.log('departmentError1', err.response.data)
                        })
          } else {
            console.log('engineeeesss')
            axios.get(`${baseURL}/api/department/${[...res.data][2]?.id}`)
            .then(res => {
              setCurso(res.data)
            })
            .catch(err => {
              console.log('departmentError1', err.response.data)
            })
          
        }
      }
      })
      localStorage.getItem('path') && setSection(localStorage.getItem('path'))
      window.scrollTo(0, 0)

      
    }, [location.state])
  
    useEffect(() => {
        console.log('carlos')
        localStorage.getItem('path') && setSection(localStorage.getItem('path'))
    }, [])

    const secondContainerRef = useRef(null)
    useEffect(() => {

      const adjustMargin = () => {
        if (secondContainerRef.current) {
            const outerContainerHeight = document.querySelector('.outerContainer')?.clientHeight  + 10|| 0;
            secondContainerRef.current.style.marginTop = `${outerContainerHeight}px`;
        }
    };

    const intervalId = setInterval(() => {
        adjustMargin(); // Call the function to adjust margin at regular intervals
    }, 1); // Adjust the interval time as needed

    // Cleanup the interval on component unmount
    return () => {
        clearInterval(intervalId);
    };
        
    })
    
    return (
        <div className="abtCont">
            <Header />
            <div className="aboutFirst" ref={secondContainerRef} style={{marginTop: document.querySelector('.outerContainer')?.clientHeight + 10}}>
              <div className="navigation">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.5.5 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.5.5 0 0 0-.196 0zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1z"/>
                  </svg>
                  <span onClick={() => {
                              navigate('/')
                          }}>Home</span> <span>{'>'}</span><span>{section}</span>
                </div>
                <StaggeredDropDown departamentos={departamentos} section={section} setSection={setSection} />
            </div>
            
            <div className="aboutContainer">
                
                <section className="secondAboutSection" style={{flex: 1}}>
                {
                        section.includes('Saúde') ?
                        <HealthPage curso={location.pathname.includes('curso/') ? departamentos[0] : curso} departamentos={departamentos}/>
                        : section.includes('Sociais') ? <SocialsPage curso={location.pathname.includes('curso/') ? departamentos[1] : curso} departamentos={departamentos}/>
                        : <EnginesPage curso={location.pathname.includes('curso/') ? departamentos[2] : curso} departamentos={departamentos}/>
                    }
                </section>
            </div>
            
            <Footer />
        </div>
    )
}
export default Courses
