import { useEffect } from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import '../css/impact.css'

const Impact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className="abtCont">
            <Header />
            <div className="impactContainer" dangerouslySetInnerHTML={{__html: 
                `
                <a
      class="bear-link"
      href="https://twitter.com/intent/follow?screen_name=jh3yy"
      target="_blank"
      rel="noreferrer noopener"
    >
      <svg
        class="w-9"
        viewBox="0 0 969 955"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="161.191"
          cy="320.191"
          r="133.191"
          stroke="currentColor"
          stroke-width="20"
        ></circle>
        <circle
          cx="806.809"
          cy="320.191"
          r="133.191"
          stroke="currentColor"
          stroke-width="20"
        ></circle>
        <circle
          cx="695.019"
          cy="587.733"
          r="31.4016"
          fill="currentColor"
        ></circle>
        <circle
          cx="272.981"
          cy="587.733"
          r="31.4016"
          fill="currentColor"
        ></circle>
        <path
          d="M564.388 712.083C564.388 743.994 526.035 779.911 483.372 779.911C440.709 779.911 402.356 743.994 402.356 712.083C402.356 680.173 440.709 664.353 483.372 664.353C526.035 664.353 564.388 680.173 564.388 712.083Z"
          fill="currentColor"
        ></path>
        <rect
          x="310.42"
          y="448.31"
          width="343.468"
          height="51.4986"
          fill="#FF1E1E"
        ></rect>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M745.643 288.24C815.368 344.185 854.539 432.623 854.539 511.741H614.938V454.652C614.938 433.113 597.477 415.652 575.938 415.652H388.37C366.831 415.652 349.37 433.113 349.37 454.652V511.741L110.949 511.741C110.949 432.623 150.12 344.185 219.845 288.24C289.57 232.295 384.138 200.865 482.744 200.865C581.35 200.865 675.918 232.295 745.643 288.24Z"
          fill="currentColor"
        ></path>
      </svg>
    </a>
    <nav style="display: none"></nav>
    <main>
      <article style="--index: 0">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=1" alt="" />
        </div>
        <div class="article__info">
          <h2>CSS Scroll Animations</h2>
          <p>Check out this rad demo</p>
          <a href="#">Follow</a>
        </div>
      </article>
      <article style="--index: 1">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=2" alt="" />
        </div>
        <div class="article__info">
          <h2>Animate on scroll</h2>
          <p>Works with media queries too</p>
          <a href="#">Check it out</a>
        </div>
      </article>
      <article style="--index: 2">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=3" alt="" />
        </div>
        <div class="article__info">
          <h2>Parallax Effects</h2>
          <p>Tweak your timings</p>
          <a href="#">Get Styling</a>
        </div>
      </article>
      <article style="--index: 3">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=4" alt="" />
        </div>
        <div class="article__info">
          <h2>Drive Keyframes</h2>
          <p>CSS alone and...</p>
          <a href="#">No JavaScript</a>
        </div>
      </article>
      <article style="--index: 4">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=5" alt="" />
        </div>
        <div class="article__info">
          <h2>Extra Slides</h2>
          <p>More content because...</p>
          <a href="#">CSS to the moon</a>
        </div>
      </article>
      <article style="--index: 5">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=6" alt="" />
        </div>
        <div class="article__info">
          <h2>Resize Window</h2>
          <p>Try resizing the window</p>
          <a href="#">Resize</a>
        </div>
      </article>
      <article style="--index: 6">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=7" alt="" />
        </div>
        <div class="article__info">
          <h2>Browser support</h2>
          <p>You'll need to be in Chrome</p>
          <a href="#">Do it</a>
        </div>
      </article>
      <article style="--index: 7">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=8" alt="" />
        </div>
        <div class="article__info">
          <h2>Your idea</h2>
          <p>What would you make?</p>
          <a href="#">Code</a>
        </div>
      </article>
      <article style="--index: 8">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=9" alt="" />
        </div>
        <div class="article__info">
          <h2>Fork</h2>
          <p>Fork this and make it your own!</p>
          <a href="#">Duplicate</a>
        </div>
      </article>
      <article style="--index: 9">
        <div class="article__img">
          <img loading="lazy"src="https://picsum.photos/1920/1080?random=10" alt="" />
        </div>
        <div class="article__info">
          <h2>Heart</h2>
          <p>If you like this, heart it (3x)</p>
          <a href="#">Like</a>
        </div>
      </article>
    </main>`
                
            }}>
                
            </div>
            {/* <Footer /> */}
        </div>
    )
}
export default Impact